const booking = {
  pageTitle: 'Ride Buchen',
  pageSubtitle: 'Buche eine einzelne Experience oder kaufe ein Abo.',
  pageBtnLabel: 'Jetzt Buchen',
  loadingPrice: 'Wird geladen...',
  invalidTime: 'Zeit wählen',
  title: 'Single Ride Buchen',
  groupTitle: 'Group Ride Buchen',
  singleRideFormTitle: 'Single Ride Buchen',
  groupRideFormTitle: 'Group Ride Buchen',
  subtitle: 'Konfiguriere und reserviere deine Experience.',
  minutes: 'Min',
  simulators: 'Simulatoren',
  ridesSharing: 'Paket Teilen',
  freeRide: 'Gratis',
  payNow: 'Jetzt bezahlen',
  summary: 'Zusammenfassung',
  booking: 'Buchen',
  lounge: 'Lounge',
  duration: 'Dauer',
  date: 'Datum',
  time: 'Zeit',
  vatInfo: 'Preise in {{currency}} inkl {{vat}}% MwSt.',
  total: 'Total',
  raceDay: 'Renntag',
  currentDay: 'Heute',
  fullyBooked: 'Nicht verfügbar',
  nonBookable: 'Geschlossen',
  getNow: 'Jetzt kaufen',
  choseMembershipPackageTitle: 'Abonnement Kaufen',
  choseMembershipPackageSubtitle:
    'Kaufe ein Abonement und geniesse die ultimative Experience.',
  package: 'Package',
  description: 'Beschreibung',
  planUpgradeSuccess: 'Mitgliedschaft aktiv!',
  bookingSuccessTitle: 'Herzlichen Dank für deine Buchung.',
  subscriptionSuccessTitle:
    'Herzlichen Dank und viel Spass mit deinem neuen Abonnement.',
  close: 'Schliessen',
  widgetText: 'Wähle eine Experience, und buche schnell und einfach.',
  widgetTextGroup: 'Wähle group Experience, und buche schnell und einfach..',
  memberships: 'Mitgliedschaften',
  price: 'Preis',
  enterCouponCode: 'Rabatt-Code eingeben',
  couponApply: 'Anwenden',
  couponApplied: 'Angewendet!',
  timeSlotError: 'Bitte wählen sie ein zeitfenster.',
  timeError: 'Bitte wählen sie die reservierungszeit.',
  participants: 'Teilnehmer',
  program: 'Programm',
  extra: 'Extra',
  organiser: 'Veranstalter',
  food: 'Essen',
  summaryAndPayment: 'Zusammenfassung & Bezahlung',
  priceSummary: 'Preisübersicht',
  saveEventAsDraft: 'Ereignis als Entwurf speichern',
  exit: 'Ausfahrt',
  createAnAccountInfo:
    'Um einen Termin als Entwurf zu speichern, müssen Sie sich zunächst anmelden oder ein Konto erstellen.',
  savingEventAsADraft: 'Ereignis als Entwurf speichern',
  savingEventAsADraftInfo:
    'Sie speichern alle Veranstaltungsdetails <1>außer dem Zeitfenster</1>.<br />Das Zeitfenster wird erst nach der Bestätigung der Veranstaltung für Sie gesperrt.',
  deleteDraftEvent: 'Löschen Sie den Veranstaltungsentwurf',
  deleteDraftEventOk: 'Ereignisentwurf löschen',
  deleteDraftEventInfo:
    'Sind Sie sicher, dass Sie Ihre Veranstaltung absagen wollen?\nDiese Aktion kann nicht rückgängig gemacht werden',
  dateAndTime: 'Datum & Uhrzeit',
  simulatorsAndDuration: 'Simulatoren & Dauer',
  location: 'Standort',
  draftEventHasBeenSaved: 'Das Ereignis wurde als Entwurf gespeichert',
  eventHasBeenCreated: 'Das Ereignis ist erstellt worden',
  noEventDraftFound: 'Kein Veranstaltungsentwurf gefunden',
  summaryEmptyState:
    'Der Preis ist nicht verfügbar. Bitte konfigurieren Sie Ihre Veranstaltung',
  saveAsDraftDisabledTooltip:
    'Als Entwurf speichern ist erst verfügbar, wenn die Schritte Lounge, Dauer und Datum abgeschlossen sind',
  eventBookingTitle: 'Veranstaltung buchen',
  yourExperience: 'Ihre Erfahrung',

  navigationButtons: {
    back: 'Zurück',
    summary: 'Zusammenfassung',
  },
  steps: {
    back: 'Zurück',
    caption:
      'Wenn unsere Standardoptionen nicht Ihren Bedürfnissen entsprechen, <1>können Sie uns</1> für eine maßgeschneiderte Veranstaltung kontaktieren.',
    mobileIsNotAllowed:
      'Ups, dieser Teil befindet sich noch im Aufbau. Wenn Sie ihn nutzen möchten, aktivieren Sie bitte <1>„Desktop Mode“</1> auf Ihrem mobilen Browser oder verwenden Sie <1>Ihren Desktop</1>.',
    participants: {
      title: 'Wie viele Teilnehmer werden an der Veranstaltung teilnehmen?',
      subtitle:
        'Dies hilft uns, die Dauer Ihrer Veranstaltung abzuschätzen und geeignete Lounges vorzuschlagen.',
      error:
        'In der ausgewählten Lounge sind standardmäßig nur bis zu {{participantsCount}} Teilnehmer möglich. Um Ihre Anforderungen zu erfüllen, <1>kontaktieren Sie uns</1> bitte direkt',
      counterLabel: 'Anzahl der Teilnehmer',
      forwardButton: 'Lounge auswählen',
    },
    experience: {
      participants: {
        title: 'Wählen Sie die Anzahl der Teilnehmer',
        discountInfo: 'Bis zu 25% Rabatt für Gruppenfahrten!',
      },
      discountTooltip: {
        title: 'Buchen Sie mehr Simulatoren und erhalten Sie Rabatte',
      },
      duration: {
        title: 'Wählen Sie die Dauer',
        info: 'Sie können das Rennformat in der Lounge auswählen',
        cardOptionsInfo: 'Optionen, die in der Lounge ausgewählt werden können',
      },
      minTooltip: 'Die Mindestteilnehmerzahl beträgt {{value}}.',
      maxTooltip:
        'Für die ausgewählte Lounge beträgt die maximale Teilnehmerzahl {{value}}.',
    },
    lounge: {
      title: 'Wo möchten Sie die Veranstaltung durchführen?',
      forwardButton: 'Dauer auswählen',
    },
    location: {
      title: 'Wo möchten Sie ein Rennen fahren?',
      forwardButton: 'Lounge auswählen und fortfahren',
      mapButton: 'Auf Karte anzeigen',
    },
    duration: {
      title: 'Wie lange soll Ihre Veranstaltung dauern?',
      subtitle:
        'Basierend auf unserer Erfahrung haben wir zwei Varianten für die Veranstaltungsdauer vorbereitet.',
      forwardButton: 'Datum wählen',
      bestseller: 'Unser Bestseller',
      counterSuffix: 'stunden',
      from: 'von',
      emptyState:
        'Wenn Ihre Anfrage ein spezielles Format benötigt, <1> Sie sich bitte an unser Team.</1>',
    },
    date: {
      title: 'Wann möchten Sie Ihre Veranstaltung durchführen?',
      subtitle:
        'Hier sind die möglichen Termine für die gewählte Teilnehmerzahl, den Ort und die Veranstaltungsdauer.',
      forwardButton: 'Speisen und Getränke auswählen',
      emptySlots:
        'An diesem Tag gibt es keine freien Termine.\nWenn Sie keinen passenden Termin finden, kontaktieren Sie uns:',
      contactUs: 'Kontakt zu Racing Unleashed',
    },
    food: {
      title: 'Was möchten Sie gerne essen?',
      subtitle:
        'Wählen Sie eines unserer definierten Sets oder beschreiben Sie Ihre speziellen Wünsche.',
      forwardButton: 'Rennprogramm Auswählen',
      person: 'person',
    },
    program: {
      title: 'Welche Veranstaltungsstruktur bevorzugen Sie?',
      subtitle:
        'Wir bieten verschiedene Arten von Renntagen an. Wählen Sie Ihre Präferenz oder entscheiden Sie vor Ort.',
      forwardButton: 'Extras auswählen',
      readMore: 'Mehr lesen',
    },
    extra: {
      title: 'Wünschen Sie zusätzliche Optionen?',
      subtitle: 'Wählen Sie eine oder mehrere Zusatzoptionen.',
      forwardButton: 'Daten des Veranstalters eingeben',
      informationPanel:
        'Vergewissern Sie sich, dass der Text keine Tipp- oder Rechenfehler enthält.',
      uploadLabel: 'Logo hochladen',
      viewExamplePhoto: 'Beispielfoto ansehen',
    },
    organiser: {
      title: 'Wer organisiert die Veranstaltung?',
      subtitle:
        'Teilen Sie uns mit, wen wir für diese Veranstaltung kontaktieren sollen.',
      forwardButton: 'Zusammenfassung ansehen',
      organiserDataTitle: 'Organisator der Veranstaltung',
      billingDataTitle: 'Daten zur Rechnungsstellung',
      company: 'Unternehmen',
      privateCustomer: 'Privater Kunde',
    },
    summary: {
      eventSummary: 'Zusammenfassung der Veranstaltung',
      bookingSummary: 'Zusammenfassung der Buchung',
      youAreOneStepAway:
        'Sie sind einen Schritt davon entfernt, Ihre Motoren zu starten!',
      where: 'Wo',
      when: 'Wann',
      raceProgram: 'Rennprogramm',
      editEventDetails: 'Veranstaltungsdetails bearbeiten',
      editBookingDetails: 'Buchungsdetails bearbeiten',
      yourEventSupervisor: 'Ihr Veranstaltungsbetreuer',
      supervisorInfo:
        'Wenn Sie Fragen oder Wünsche haben, wenden Sie sich an Ihren Eventbetreuer {{name}}.',
      confirmButton: 'Bestätigen Sie die Buchung',
      confirmAndProceedButton: 'bestätigen und fortfahren',
      packageSubtitle: 'Entscheiden Sie, wie Sie Ihr Paket verwenden möchten.',
      emptyPackage: 'Sie haben keine gemeinsam nutzbaren Pakete.',
      billingDetails: 'Details zur Abrechnung',
      payment: 'Zahlung',
      payInLounge: 'Bezahlen in der Lounge',
      payByCard: 'Kreditkarte',
      specialRequest: 'Besondere Anfrage',
      specialRequestTooltip:
        'Ihr Veranstaltungsbetreuer wird sich mit Ihnen in Verbindung setzen, um die Einzelheiten zu besprechen.',
      billingTooltip:
        'Wenn Sie andere Daten verwenden möchten, melden Sie sich ab und füllen Sie die Daten manuell ein.',
      billingInfoError: 'Falsche Rechnungsinformationen',
    },
    thankYou: {
      title: 'Vielen Dank!',
      text: 'Sie werden in Kürze eine Bestätigung per E-Mail erhalten.\nSollten Sie zusätzliche Wünsche oder Änderungen haben, wenden Sie sich bitte an Ihren Eventbetreuer.\nWir sehen uns in der Lounge!',
      button: 'Homepage von Racing Unleashed',
    },
    rideThankYou: {
      title: 'Danke, {{name}}!',
      text: 'Wir haben Ihnen die Buchungsbestätigung per E-Mail geschickt.\nWir freuen uns, Sie in unserer Lounge begrüßen zu dürfen!',
      faqTitle:
        'Muss ich etwas mitbringen? Gibt es eine Personalbetreuung oder muss ich alles selbst herausfinden?',
      faqText:
        'Besuchen Sie den FAQ-Bereich unserer Website, um mehr darüber zu erfahren, wie Sie sich auf Ihren Besuch in der Lounge vorbereiten können.',
      checkFaqButton: 'FAQ überprüfen',
      paymentMethod: 'Zahlungsmethode',
    },
  },
};

export default booking;
