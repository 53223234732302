import { Tooltip, type TooltipProps } from '@mui/material';
import { withStyles } from '@mui/styles';
import React, { forwardRef } from 'react';

const TooltipWithContent = forwardRef<HTMLDivElement, TooltipProps>(
  function TooltipWithContent({ children, ...restProps }, ref) {
    return (
      <Tooltip {...restProps}>
        <div ref={ref}>{children}</div>
      </Tooltip>
    );
  },
);

const StyledTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: '#000047',
    color: '#fff',
    boxShadow: 'unset',
    fontSize: 12,
    maxWidth: '500px',
  },
  arrow: {
    color: '#000047',
  },
}))(TooltipWithContent);

export default StyledTooltip;
