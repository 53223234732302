import React, { useMemo, useState } from 'react';
import styles from './EventSummary.module.scss';
import { Trans, useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { IEventBookForm } from '../../EventBooking';
import { Lounge, Supervisor } from '../../../../store/lounge/lounge.types';
import useWithSelection from '../../../../hooks/useWithSelection';
import { loungeSelector } from '../../../../store/lounge/lounge.selectors';
import Button from '../../../../components/Button';
import { ReactComponent as EditIcon } from '../../../../images/edit.svg?tsx';
import { ReactComponent as CheckIcon } from '../../../../images/check-white.svg?tsx';
import oneStepAwayImg from '../../../../images/booking-summary/one-step-away.png';
import AppFormField from '../../../../components/AppFormField/AppFormField';
import AppTextarea from '../../../../components/AppTextarea';
import CheckboxRadio from '../../../../components/FormField/CheckboxRadio';
import {
  EEventProductType,
  EventProducts,
} from '../../../../store/eventProducts/eventProducts.types';
import Loader from '../../../../components/Loader';
import { add, format, parse } from 'date-fns';
import { timeFromIsoDate } from '../../../../utils/time-from-iso-date.utils';
import { getFormError } from '../../../../utils/get-form-error.utils';
import { emojisValidator } from '../../../../validators/emojis.validator';
import { dateLocales } from '../../../../constants/dateLocales';
import i18n from '../../../../i18n';
import BookingDetailsList, {
  BookingDetail,
} from '../../../../components/BookingDetailsList/BookingDetailsList';
import { getLoungeAddress } from '../../../../utils/get-lounge-address.utils';
import BookingPriceDetails, {
  BookingPriceDetail,
} from '../../../../components/BookingPriceDetails/BookingPriceDetails';
import { SummaryType } from '../../../../store/booking/booking.types';
import BookingCouponField from '../../../../components/BookingCouponField/BookingCouponField';
import { Controller } from '../../../../components/FormField/Controller';

interface IEventSummaryProps {
  supervisor: Supervisor | null;
  isPriceLoading: boolean;
  isSubmitting: boolean;
  priceDetails: BookingPriceDetail[];
  priceSummary: SummaryType | null;
  eventOptions: EventProducts;
  toggleSummary: () => void;
  onSubmit: () => void;
}

const EventSummary: React.FC<IEventSummaryProps> = ({
  supervisor,
  isPriceLoading,
  isSubmitting,
  priceDetails,
  priceSummary,
  eventOptions,
  toggleSummary,
  onSubmit,
}) => {
  const [t] = useTranslation();
  const lounges: Lounge[] = useWithSelection(loungeSelector);
  const {
    control,
    formState: { errors },
    getValues,
    setValue,
  } = useFormContext<IEventBookForm>();
  const [privacyRead, setPrivacyRead] = useState<boolean>(false);
  const [termsRead, setTermsRead] = useState<boolean>(false);

  const {
    participantsAmount,
    loungeId,
    foodId,
    programId,
    selectedExtras,
    time,
    duration,
  } = getValues();

  const eventSummaryDetails: BookingDetail[] = useMemo(() => {
    const lounge = lounges.find(({ id }) => id === loungeId);
    const loungeAddress = lounge ? getLoungeAddress(lounge) : '';
    const food = eventOptions[EEventProductType.eventCatering].find(
      ({ id }) => id === foodId,
    );
    const program = eventOptions[EEventProductType.eventStructure].find(
      ({ id }) => id === programId,
    );
    const startTime = time ? timeFromIsoDate(time) : '';
    const dateTime = time
      ? `${format(new Date(time), 'dd MMMM yyy', {
        locale: dateLocales[i18n.language],
      })}, ${timeFromIsoDate(time)?.[0]} - ${
        timeFromIsoDate(
          add(parse(startTime ? startTime[0] : '', 'HH:mm', new Date(time)), {
            minutes: duration * 60,
          }).toString(),
        )?.[0]
      }`
      : '';

    const extra = selectedExtras
      .map((id) => {
        switch (id) {
          case eventOptions.event_trophies.length
            && eventOptions.event_trophies[0].id:
            return eventOptions.event_trophies[0].label;
          case eventOptions.event_car_branding.length
            && eventOptions.event_car_branding[0].id:
            return eventOptions.event_car_branding[0].label;
          case eventOptions.event_track_branding.length
            && eventOptions.event_track_branding[0].id:
            return eventOptions.event_track_branding[0].label;
          default:
            return '';
        }
      })
      .filter(Boolean)
      .join(',\n');

    return [
      {
        label: 'booking.participants',
        value: participantsAmount,
      },
      {
        label: 'booking.steps.summary.where',
        value: loungeAddress,
      },
      {
        label: 'booking.steps.summary.when',
        value: dateTime,
      },
      {
        label: 'booking.food',
        value: food ? food.label : '',
      },
      {
        label: 'booking.steps.summary.raceProgram',
        value: program ? program.label : '',
      },
      {
        label: 'booking.extra',
        value: extra || '-',
      },
    ] as BookingDetail[];
  }, [
    eventOptions,
    participantsAmount,
    loungeId,
    foodId,
    programId,
    selectedExtras,
    lounges,
    time,
    duration,
  ]);

  const allTermsAgreed = privacyRead && termsRead;

  return (
    <div className={styles.eventSummary}>
      <div className={styles.eventSummary__column}>
        <h3 className={styles.eventSummary__title}>
          {t('booking.steps.summary.eventSummary')}
        </h3>
        <BookingDetailsList details={eventSummaryDetails} />
        <Button
          appearance="outline"
          disabled={isSubmitting}
          className={styles.eventSummary__button}
          icon={<EditIcon />}
          label={t('booking.steps.summary.editEventDetails')}
          onClick={toggleSummary}
        />
        <Controller
          control={control}
          name="additionalRequest"
          rules={{
            ...emojisValidator({
              invalid: t('field.validations.emojisNotAllowed'),
            }),
          }}
          render={({ value, onChange }) => (
            <AppFormField
              label="field.additionalRequest.label"
              currentLength={value.length}
              maxLength={512}
              error={getFormError('additionalRequest', errors)}
            >
              <AppTextarea
                disabled={isSubmitting}
                value={value}
                rows={4}
                maxLength={512}
                placeholder="field.additionalRequest.placeholder"
                onChange={(event) => onChange(event.target.value)}
              />
            </AppFormField>
          )}
        />
      </div>
      <div className={styles.eventSummary__column}>
        <h3 className={styles.eventSummary__title}>
          {t('booking.steps.summary.youAreOneStepAway')}
        </h3>
        <img
          className={styles.eventSummary__image}
          src={oneStepAwayImg}
          alt="You are one step away"
        />
        {supervisor
          ? (
              <div className={styles.supervisor}>
                <h3 className={styles.eventSummary__title}>
                  {t('booking.steps.summary.yourEventSupervisor')}
                </h3>
                <p>
                  {t('booking.steps.summary.supervisorInfo', {
                    name: supervisor.first_name,
                  })}
                </p>
                <div className={styles.supervisor__card}>
                  <img
                    src={supervisor.profile_pic_url}
                    alt={supervisor.first_name}
                  />
                  <div>
                    <p className={styles.supervisor__name}>
                      {supervisor.first_name}
                    </p>
                    <p>{supervisor.phone_number}</p>
                  </div>
                </div>
              </div>
            )
          : (
              ''
            )}
      </div>
      <div className={styles.eventSummary__column}>
        <h3 className={styles.eventSummary__title}>
          {t('booking.priceSummary')}
        </h3>
        <div className={styles.priceSummary}>
          <BookingPriceDetails
            details={priceDetails}
            priceSummary={priceSummary}
            emptyStateKey="booking.summaryEmptyState"
          />
          {isPriceLoading
            ? (
                <Loader fullSize color="black" width={80} height={80} />
              )
            : (
                ''
              )}
        </div>
        <BookingCouponField disabled={isSubmitting} onSend={(coupon) => setValue('coupon', coupon)} />
        <div className={styles.eventSummary__checkboxes}>
          <CheckboxRadio
            primary
            required
            disabled={isSubmitting}
            checked={privacyRead}
            name="privacyPolicy"
            value="privacyPolicy"
            i18nTrans={(
              <Trans
                i18nKey="field.privacyPolicy.label"
                components={{
                  1: (
                    <a
                      className={styles.eventSummary__link}
                      href={t('field.privacyPolicy.link')}
                      target="_blank"
                      rel="noopener noreferrer"
                    />
                  ),
                }}
              />
            )}
            onChange={({ target: { checked } }) => setPrivacyRead(checked)}
          />
          <CheckboxRadio
            primary
            required
            disabled={isSubmitting}
            checked={termsRead}
            name="termsOfUse"
            value="termsOfUse"
            i18nTrans={(
              <Trans
                i18nKey="field.termsOfUse.label"
                components={{
                  1: (
                    <a
                      className={styles.eventSummary__link}
                      href={t('field.termsOfUse.link')}
                      target="_blank"
                      rel="noopener noreferrer"
                    />
                  ),
                }}
              />
            )}
            onChange={({ target: { checked } }) => setTermsRead(checked)}
          />
        </div>
        <div className={styles.action}>
          <Button
            disabled={!allTermsAgreed || isSubmitting}
            isLoading={isSubmitting}
            label={t('booking.steps.summary.confirmButton')}
            icon={<CheckIcon />}
            onClick={allTermsAgreed ? () => onSubmit() : undefined}
          />
        </div>
      </div>
    </div>
  );
};

export default EventSummary;
