import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getTimeSlots } from '../../store/booking/booking.actions';
import Button from '../Button';
import TimeSlots from '../TimeSlots';
import { TimeSlot } from '../../store/booking/booking.types';
import styles from './BookingWidget.module.scss';
import { customerSelector } from '../../store/customer/customer.selectors';
import useWithSelection from '../../hooks/useWithSelection';
import { toastUtil } from '../../utils/toast.utils';
import classNames from 'classnames';

interface Props {
  isGroupRide?: boolean;
}

const BookingWidget = ({ isGroupRide }: Props) => {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const { default_lounge_id: defaultCustomerLoungeId } = useWithSelection(
    customerSelector,
  );
  const [timeSlot, setTimeSlot] = useState<TimeSlot | null>(null);
  const [timeSlots, setTimeSlots] = useState<TimeSlot[]>([]);

  useEffect(() => {
    const abortController = new AbortController();
    (async () => {
      try {
        const response = await getTimeSlots(
          isGroupRide,
          undefined,
          undefined,
          abortController,
        );
        setTimeSlots(response);
      }
      catch (err) {
        const error = err as TRumpApiErrorResponseData;
        if ('message' in error && error.message === 'canceled') return;

        const msg = 'meta' in error ? error.meta.message : (error as Error).message;
        if (typeof msg === 'string') toastUtil('error', msg);
      }
    })();

    return () => {
      abortController.abort();
    };
    // eslint-disable-next-line
  }, []);

  const handleBookButtonClick = useCallback(() => {
    const query = new URLSearchParams();

    if (defaultCustomerLoungeId) {
      query.set('loungeId', defaultCustomerLoungeId);
    }

    if (timeSlot) {
      query.set('duration', timeSlot.duration_in_minutes.toString());
    }

    if (isGroupRide) {
      query.set('isGroupRide', isGroupRide.toString());
    }

    navigate(`/public/ride-booking?${query.toString()}`);
  }, [navigate, defaultCustomerLoungeId, timeSlot, isGroupRide]);

  return (
    <div className={classNames('dashboard-widget', 'flex', styles.root)}>
      <h3>{t(`booking.${isGroupRide ? 'groupTitle' : 'title'}`)}</h3>
      <p className="dashboard-widget-text">
        {t(`booking.widgetText${isGroupRide ? 'Group' : ''}`)}
      </p>
      <TimeSlots
        selected={timeSlot}
        selectSlot={setTimeSlot}
        timeSlots={timeSlots}
      />
      <Button
        fullWidth
        className={styles.root__button}
        label={t('booking.pageBtnLabel')}
        onClick={handleBookButtonClick}
      />
    </div>
  );
};

export default BookingWidget;
