import React from 'react';
import styles from './BookingPriceDetails.module.scss';
import StyledTooltip from '../StyledTooltip/StyledTooltip';
import { useTranslation } from 'react-i18next';
import { ReactComponent as InfoIcon } from '../../images/Info-current-color.svg?tsx';
import { SummaryType } from '../../store/booking/booking.types';
import classNames from 'classnames';
import { round } from '../../utils/round.utils';

export type BookingPriceDetail = {
  label: string;
  value: string;
  hasSpecial?: boolean;
};

type BookingPriceDetailsProps = {
  emptyStateKey: string;
  details: BookingPriceDetail[];
  priceSummary?: SummaryType | null;
};

const BookingPriceDetails: React.FC<BookingPriceDetailsProps> = ({
  emptyStateKey,
  details,
  priceSummary,
}) => {
  const [t] = useTranslation();

  if (!details.length)
    return <div className={styles.emptyState}>{t(emptyStateKey)}</div>;

  return (
    <div className={styles.priceDetails}>
      {details.map(({ label, value, hasSpecial }) => (
        <React.Fragment key={label}>
          <div className={styles.priceDetails__title}>
            <p>{label}</p>
            {hasSpecial
              ? (
                  <p className={styles.priceDetails__specialRequest}>
                    {'+' + t('booking.steps.summary.specialRequest').toLowerCase()}
                    <StyledTooltip
                      arrow
                      enterTouchDelay={0}
                      placement="top-start"
                      title={
                        t('booking.steps.summary.specialRequestTooltip')
                      }
                    >
                      <InfoIcon />
                    </StyledTooltip>
                  </p>
                )
              : (
                  ''
                )}
          </div>
          <div className={styles.priceDetails__price}>{value}</div>
        </React.Fragment>
      ))}
      {priceSummary
        ? (
            <>
              <div className={styles.priceDetails__total}>
                <span>{t('booking.total')}</span>
                <span className={styles.caption}>
                  {t('booking.vatInfo', {
                    vat: priceSummary.products[0].vat,
                    currency: priceSummary.currency,
                  })}
                </span>
              </div>
              <div
                className={classNames(
                  styles.priceDetails__total,
                  styles.priceDetails__price,
                  styles.priceDetails__price_big,
                )}
              >
                {priceSummary.final_price_sum !== null && priceSummary.final_price_sum !== undefined
                  ? round(priceSummary.final_price_sum)
                  : ''}
              </div>
            </>
          )
        : (
            ''
          )}
    </div>
  );
};

export default BookingPriceDetails;
