import React, { FunctionComponent, MouseEvent } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import Button from '../Button';

import './Summary.scss';

interface Summary {
  onClick: (event: MouseEvent<HTMLButtonElement>) => void;
  buttonLabel: string;
  currency: string | null | undefined;
  totalPrice: number | null | undefined;
  disabled?: boolean;
  hasSummary?: boolean;
  vat?: string | null | undefined;
  isLoading?: boolean;
  children?: React.ReactNode;
}

const Summary: FunctionComponent<Summary> = ({
  children,
  onClick,
  buttonLabel,
  currency,
  totalPrice,
  hasSummary,
  vat,
  isLoading = false,
}) => {
  const [t] = useTranslation();

  return (
    <div className="Summary">
      <div className={`Summary-items ${hasSummary ? 'alt' : ''}`}>
        <h2>{t('booking.summary')}</h2>
        {children}
      </div>
      <div className="Summary-payment">
        {vat && (
          <div className="Summary-vat">
            <Trans i18nKey="booking.vatInfo" values={{ vat, currency }}>
              Prices are in
              {' '}
              {currency}
              {' '}
              including
              {' '}
              {vat}
              % Vat.
            </Trans>
          </div>
        )}
        <div className="Summary-total">
          <span>{t('booking.total')}</span>
          <span>
            {currency && currency}
            {' '}
            {totalPrice ? totalPrice.toFixed(2) : '0.00'}
          </span>
        </div>
        <Button
          fullWidth
          label={buttonLabel}
          onClick={onClick}
          isLoading={isLoading}
          disabled={!hasSummary || isLoading}
        />
      </div>
    </div>
  );
};

export default Summary;
