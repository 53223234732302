import React from 'react';
import { TimeSlot } from '../../store/booking/booking.types';
import { SetStateType } from '../../types/utils.types';
import { useTranslation } from 'react-i18next';

import './TimeSlots.scss';

interface TimeSlots {
  selectSlot: SetStateType<TimeSlot | null>;
  selected: TimeSlot | null;
  timeSlots: TimeSlot[];
  showWarning?: boolean;
}

interface Slot {
  slot: TimeSlot;
  select: SetStateType<TimeSlot | null>;
  isSelected: boolean | null;
}

const Slot = ({ select, slot, isSelected }: Slot) => {
  const clickHandler = () => {
    if (isSelected) return;
    select(slot);
  };

  return (
    <span className={isSelected ? 'is-selected' : ''} onClick={clickHandler}>
      {slot.duration_in_minutes}
    </span>
  );
};

const TimeSlots = React.memo(
  function TimeSlots({ selected, selectSlot, timeSlots, showWarning }: TimeSlots) {
    const [t] = useTranslation();

    return (
      <div className="TimeSlots">
        {timeSlots.map((slot: TimeSlot) => (
          <Slot
            key={slot.id}
            slot={slot}
            select={selectSlot}
            isSelected={selected && selected.id === slot.id}
          />
        ))}
        {' '}
        min
        {showWarning && <p>{t('booking.timeSlotError')}</p>}
      </div>
    );
  },
);

export default TimeSlots;
