import React, { useEffect, useRef } from 'react';
import type { AnimationConfigWithData } from 'lottie-web';
import lottie from 'lottie-web/build/player/lottie_light';

export interface ILottieSvgProps extends React.HTMLProps<HTMLDivElement> {
  options: Omit<AnimationConfigWithData<'svg'>, 'container' | 'renderer'>;
}

const LottieSvg = ({ options, ...restProps }: ILottieSvgProps) => {
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!containerRef.current) return;

    const animation = lottie.loadAnimation({
      ...options,
      container: containerRef.current,
      renderer: 'svg',
    });

    return () => {
      animation.destroy();
    };
  }, [options]);

  return <div style={{ display: 'flex' }} {...restProps} ref={containerRef} />;
};

export default LottieSvg;
